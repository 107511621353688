import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import * as hasura_queries from "./graphql_hasura/queries";
import { eventBus } from "./main.js";

import { initializeLambdaClient } from "@/utilities/SessionUtils";

//import router from './router';
// import Amplify from 'aws-amplify';

//import {Animal } from "@/models";

//var TimestreamQuery = require("aws-sdk/clients/timestreamquery");

Auth.currentUserInfo;

var sem_status = {};

Vue.use(Vuex);

const weatherService = {
  state: {
    error: null,
    loading: false,
    // current
    currentWeather: Object,
    currentTemp: "",
    minTemp: "",
    maxTemp: "",
    feelsLike: "",
    sunrise: "",
    sunset: "",
    pressure: "",
    humidity: "",
    wind: "",
    overcast: "",
    name: "",
    UV: "",
    visibility: "",
    wind_speed: "",
    wind_degree: "",
    wind_gust: "",
    clouds: "",
    latitude: "",
    longitude: "",
    hourlyHours: [],
    current: Object,
    daily: Object,
    hourly: Object,
  },
  mutations: {
    SET_WEATHER_DATA(state, payload) {
      // current

      //state.current = payload.current
      state.currentTemp = payload.main.temp;
      state.currentWeather = payload.weather[0].description;
      state.sunrise = new Date(payload.sys.sunrise * 1000).toLocaleString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
        }
      );
      state.sunset = new Date(payload.sys.sunset * 1000).toLocaleString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
        }
      );
      state.feelsLike = payload.main.feels_like + "°C";
      state.humidity = payload.main.humidity + "%";
      //state.UV = payload.current.uvi;
      state.clouds = payload.clouds.all + "%";
      state.visibility = payload.visibility / 1000 + " km";
      state.wind_speed = (payload.wind.speed * 3.6).toPrecision(2) + " km/h";
      state.wind_degree = payload.wind.deg + "°";
      // daily
      state.daily = payload.daily;
      // hourly
      state.hourly = payload.hourly;
    },
  },
  actions: {
    GET_WEATHER_DATA({ commit, state }, coords) {
      axios
        .get(
          "https://api.openweathermap.org/data/2.5/weather?lat=" +
            coords[1] +
            "&lon=" +
            coords[0] +
            "&exclude=minutely" +
            "&units=metric&appid=" +
            "9fffbcda9b424a299c3f2b549a4b8679"
        )
        .then((response) => {
          commit("SET_WEATHER_DATA", response.data);
          state.loading = false;
        });
    },
  },
  getters: {
    weatherGetter(state) {
      return state;
    },
  },
};

const store = new Vuex.Store({
  modules: {
    w: weatherService,
  },
  state: {
    lambda_client: null,
    authorized: false,
    user: null,
    user_ddb: false,
    currentTemp: "",
    loginError: "",
    signupError: "",
    latitude: "",
    longitude: "",
    coordinates: Object,
    button: Boolean,
    // loadedCattle: [],
    ddbGetData: [],
    cameras: [],
    camera_names_object: {},
    cam_types: [],
    cam_names: [],
    video_list: [],
    thumbnails: [],
    alerts: [],
    notices: [],
    doneFetching: false,
    jetson: "",
    sites: [],
    camera_objects: [],
    wifi_object: [],
    selected_camera: null,
    notifications: null,
    notification_count: 0,
    herds: [],
    groups: [],
    last_footage: [],
    carcasses: [],
    phenotypes: [],
    animal: null,
    notes: [],
    note_count: 0,
    zones: [],
    most_recent_animals: [],
    calendar_events: [],
    calves: [],
    cows: [],
    bulls: [],
    scrotum_circumference: [],
    sperm_morphology: [],
    phenotype_charts: false,
    measurement_charts: false,
    video_query_month: null,
    video_loading: true,
    loading_model: "Initializing",
    card_events: [],
    event_params: {},
    lambda_auth_token: null,
    lambda_auth_token_home: null,
    jwt_token: null,
    customer_id: null,
    allowed_operations: "READ",
    videos_at_dates: [],
    enums: {},
    images: [],
    bbox_color_code: {
      head: {
        fillStyle: "rgba(0, 154, 255, 0.4)",
        strokeStyle: "rgba(0, 154, 255)",
      },
      tag: {
        fillStyle: "rgba(55, 255, 255, 0.4)",
        strokeStyle: "rgba(55, 255, 255)",
      },
      tail: {
        fillStyle: "rgba(255, 66, 161, 0.4)",
        strokeStyle: "rgba(255, 66, 161)",
      },
      car: {
        fillStyle: "rgba(186, 86, 36, 0.4)",
        strokeStyle: "rgba(186, 86, 36)",
      },
      cat: {
        fillStyle: "rgba(92, 103, 132, 0.4)",
        strokeStyle: "rgba(92, 103, 132)",
      },
      cow: {
        fillStyle: "rgba(242, 114, 0, 0.4)",
        strokeStyle: "rgba(242, 114, 0)",
      },
      dog: {
        fillStyle: "rgba(32, 164, 243, 0.4)",
        strokeStyle: "rgba(32, 164, 243)",
      },
      horse: {
        fillStyle: "rgba(148, 123, 211, 0.4)",
        strokeStyle: "rgba(148, 123, 211)",
      },
      person: {
        fillStyle: "rgba(240, 167, 160, 0.4)",
        strokeStyle: "rgba(240, 167, 160)",
      },
      pig: {
        fillStyle: "rgba(96, 73, 90, 0.4)",
        strokeStyle: "rgba(96, 73, 90)",
      },
      sheep: {
        fillStyle: "rgba(255, 87, 187, 0.4)",
        strokeStyle: "rgba(255, 87, 187)",
      },
      truck: {
        fillStyle: "rgba(140, 39, 30, 0.4)",
        strokeStyle: "rgba(140, 39, 30)",
      },
      coyote: {
        fillStyle: "rgba(247, 238, 127, 0.4)",
        strokeStyle: "rgba(247, 238, 127)",
      },
      bear: {
        fillStyle: "rgba(164, 180, 148, 0.4)",
        strokeStyle: "rgba(164, 180, 148)",
      },
      bison: {
        fillStyle: "rgba(196, 167, 125, 0.4)",
        strokeStyle: "rgba(196, 167, 125)",
      },
    },

    insights_by_species: [],
    insights_by_camera: [],

    analytics_predators_by_pgie_count: [],
    analytics_contractions_by_pgie_count: [],
    analytics_animals_by_pgie_count: [],

    analytics_animal_count: [],
    analytics_contraction: [],
    analytics_predator: [],

    animal_identifications: [],

    hasura_params: {
      url: process.env.VUE_APP_HASURA_ENDPOINT_READ,
      headers: {
        Authorizaation: "",
        "Content-Type": "application/json",
      },
    },

    hasura_params_write: {
      url: process.env.VUE_APP_HASURA_ENDPOINT_WRITE,
      headers: {
        Authorizaation: "",
        "Content-Type": "application/json",
      },
    },

    hasura_params_inference: {
      url: process.env.VUE_APP_HASURA_ENDPOINT_READ,
      headers: {
        Authorizaation: "",
        "Content-Type": "application/json",
      },
    },

    physical_address: {},
    mailing_address: {},
    notification_preference: {},
    collaborations: [],
    collaborators: [],

    //ANIMALS
    animals: [],
    animal_count: 0,
    animals_by_classification: [],
    cows_by_breeding_type: [],
    cows_by_dam_status: [],
    cows_by_cod: [],
    calves_by_birth_ease: [],
    calves_by_sex: [],
    calves_by_cod: [],
    bulls_by_scrotum_circumference: [],
    bulls_by_sperm_morphology: [],
    bulls_by_sperm_motility: [],
    selection_animals: [],

    //TREATMENTS
    treatments: [],
    treatment_count: 0,
    treatments_by_last_six_months: [],
    treatments_by_reason: [],
    treatments_by_type: [],
    treatments_by_drug_name: [],

    //MEASUREMENTS
    measurements: [],
    measurement_count: [],
    animals_by_weight_birth: [],
    animals_by_weight_weaning: [],
    animals_by_weight_yearling: [],
    animals_by_weight_mature: [],

    //PHENOTYPES
    animals_by_phenotype_hoof: [],
    animals_by_phenotype_claw: [],
    animals_by_phenotype_udder: [],
    animals_by_phenotype_teat: [],

    selected_animal_parents: {},
    selected_animal_offspring: [],

    summary_media_most_recent_1day: [],
    summary_media_most_recent_1hour: [],

    summary_media: [],
    summary_pgie: [],
    summary_activity: [],
  },
  mutations: {
    SET_COLLABORATIONS(state, payload) {
      state.collaborations = payload;
    },
    SET_COLLABORATORS(state, payload) {
      state.collaborators = payload;
    },
    SET_ANIMAL_IDENTIFICATIONS(state, payload) {
      state.animal_identifications = payload;
    },

    SET_INSIGHTS(state, payload) {
      state.insights_by_camera = payload.by_camera;
      state.insights_by_species = payload.by_species;
    },

    SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT(state, payload) {
      state.analytics_predators_by_pgie_count = payload;
    },
    SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT(state, payload) {
      state.analytics_contractions_by_pgie_count = payload;
    },
    SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT(state, payload) {
      state.analytics_animals_by_pgie_count = payload;
    },
    SET_ANALYTICS_ANIMAL_IMAGE_BY_PGIE_COUNT(state, payload) {
      state.analytics_animal_image_by_pgie_count = payload;
    },
    SET_ANALYTICS_CONTRACTIONS(state, payload) {
      state.analytics_contraction = payload;
    },
    SET_ANALYTICS_PREDATORS(state, payload) {
      state.analytics_predator = payload;
    },
    SET_ANALYTICS_ANIMAL_COUNT(state, payload) {
      state.analytics_animal_count = payload;
    },

    SET_LOGIN_ERROR(state, payload) {
      state.loginError = payload;
    },
    SET_VIDEOS_AT_DATES(state, payload) {
      state.videos_at_dates = payload;
    },
    SET_ALLOWED_OPERATIONS(state, payload) {
      state.allowed_operations = payload;
    },
    SET_CUSTOMER_ID(state, payload) {
      state.customer_id = payload;
      store.dispatch("DDB_GET_SITES");
      store.dispatch("DDB_GET_CAMERAS");
      store.dispatch("DDB_GET_WIFIS");
      store.dispatch("DDB_GET_HERDS");
      store.dispatch("DDB_GET_SUMMARY_MEDIA_MOST_RECENT");
    },
    SET_JWT_TOKEN(state, payload) {
      state.jwt_token = payload;
    },
    SET_LAMBDA_AUTH_TOKEN_HOME(state, payload) {
      state.lambda_auth_token_home = payload;
    },
    SET_LAMBDA_AUTH_TOKEN(state, payload) {
      state.lambda_auth_token = payload;
    },
    SET_MEASUREMENT_CHART(state, payload) {
      state.measurement_charts = payload;
    },
    SET_PHENOTYPE_CHART(state, payload) {
      state.phenotype_charts = payload;
    },
    SET_SCROTUM_CIRCUMFERENCE(state, payload) {
      state.scrotum_circumference = payload;
    },
    SET_SPERM_MORPHOLOGY(state, payload) {
      state.sperm_morphology = payload;
    },
    SET_BULLS(state, payload) {
      state.bulls = payload;
    },
    SET_COWS(state, payload) {
      state.cows = payload;
    },
    SET_CALVES(state, payload) {
      state.calves = payload;
    },
    SET_CALENDAR_EVENTS(state, payload) {
      state.calendar_events = payload;
    },
    SET_MOST_RECENT_ANIMALS(state, payload) {
      state.most_recent_animals = payload;
    },

    SET_GROUPS(state, payload) {
      state.groups = payload;
    },
    SET_NOTES(state, payload) {
      state.notes = payload.notes;
      state.note_count = payload.note_count;
    },

    SET_CARCASSES(state, payload) {
      state.carcasses = payload;
    },
    SET_PHENOTYPES(state, payload) {
      state.phenotypes = payload;
    },
    SET_LAST_FOOTAGE(state, payload) {
      state.last_footage = payload;
    },
    SET_HERDS(state, payload) {
      state.herds = payload;
    },

    SET_WIFIS(state, payload) {
      state.wifi_object = payload;
    },
    SET_CAMERAS(state, payload) {
      state.camera_objects = payload;
    },
    SET_CAMERAS_NAME_OBJECT(state, payload) {
      state.camera_names_object = payload;
    },
    SET_SELECTED_CAMERA(state, payload) {
      state.selected_camera = payload;
    },
    SET_COORDINATES(state, payload) {
      state.coordinates = payload;
    },
    SET_SITES(state, payload) {
      state.sites = payload;
    },
    SET_CAMERA_TYPES(state, payload) {
      state.cam_types = payload;
    },
    SET_CAMERA_NAMES(state, payload) {
      state.cam_names = payload;
    },
    SET_VIDEO_LIST(state, payload) {
      state.video_list = payload;
    },
    SET_USER_DATA(state, payload) {
      state.user_ddb = payload.customer;
      state.physical_address = payload.physical_address;
      state.mailing_address = payload.mailing_address;
      state.notification_preference = payload.notification_preference;
    },
    SET_THUMBNAILS(state, payload) {
      state.thumbnails = payload;
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload.notifications;
      state.notification_count = payload.notification_count;
    },
    SET_CARD_EVENTS(state, payload) {
      state.card_events = payload;
    },
    SET_ALERTS(state, payload) {
      state.alerts = payload;
    },
    SET_NOTICES(state, payload) {
      state.notices = payload;
    },
    SET_JETSON(state, payload) {
      state.jetson = payload;
    },

    // ANIMALS
    SET_ANIMALS(state, payload) {
      state.animals = payload.animals;
      state.animal_count = payload.animal_count;
    },
    SET_ANIMAL_STATS(state, payload) {
      state.animals_by_classification = payload.animals_by_classification;
      state.cows_by_breeding_type = payload.cows_by_breeding_type;
      state.cows_by_dam_status = payload.cows_by_dam_status;
      state.cows_by_cod = payload.cows_by_cod;
      state.calves_by_birth_ease = payload.calves_by_birth_ease;
      state.calves_by_sex = payload.calves_by_sex;
      state.calves_by_cod = payload.calves_by_cod;
      state.bulls_by_scrotum_circumference =
        payload.bulls_by_scrotum_circumference;
      state.bulls_by_sperm_morphology = payload.bulls_by_sperm_morphology;
      state.bulls_by_sperm_motility = payload.bulls_by_sperm_motility;
    },
    SET_SELECTION_ANIMALS(state, payload) {
      state.selection_animals = payload;
    },
    SET_ANIMAL(state, payload) {
      state.animal = payload;
    },

    // TREATMENTS
    SET_TREATMENTS(state, payload) {
      state.treatments = payload.treatments;
      state.treatment_count = payload.treatment_count;
    },
    SET_TREATMENT_STATS(state, payload) {
      state.treatments_by_last_six_months =
        payload.treatments_by_last_six_months;
      state.treatments_by_reason = payload.treatments_by_reason;
      state.treatments_by_type = payload.treatments_by_type;
      state.treatments_by_drug_name = payload.treatments_by_drug_name;
    },

    // MEASUREMENTS
    SET_MEASUREMENTS(state, payload) {
      state.measurements = payload.measurement;
      state.measurement_count = payload.measurement_count;
    },
    SET_MEASUREMENT_STATS(state, payload) {
      state.animals_by_weight_birth = payload.animals_by_weight_birth;
      state.animals_by_weight_weaning = payload.animals_by_weight_weaning;
      state.animals_by_weight_yearling = payload.animals_by_weight_yearling;
      state.animals_by_weight_mature = payload.animals_by_weight_mature;
    },

    // PHENOTYPES
    SET_PHENOTYPE_STATS(state, payload) {
      state.animals_by_phenotype_hoof = payload.animals_by_phenotype_hoof;
      state.animals_by_phenotype_claw = payload.animals_by_phenotype_claw;
      state.animals_by_phenotype_udder = payload.animals_by_phenotype_udder;
      state.animals_by_phenotype_teat = payload.animals_by_phenotype_teat;
    },

    SET_SELECTED_ANIMAL_PARENTS(state, payload) {
      state.selected_animal_parents = payload;
    },
    SET_SELECTED_ANIMAL_OFFSPRING(state, payload) {
      state.selected_animal_offspring = payload;
    },

    SET_ZONES(state, payload) {
      state.zones = payload;
    },
    SET_ENUMS(state, payload) {
      state.enums = payload;
    },
    SET_IMAGES(state, payload) {
      state.images = payload;
    },
    user(state, user) {
      // state.authorized = !!user && user.attributes && user.attributes.email_verified
      state.authorized = !!user && user.attributes;
      state.user = user;
    },

    SET_SUMMARY_MEDIA_MOST_RECENT(state, payload) {
      state.summary_media_most_recent_1day = payload.day;
      state.summary_media_most_recent_1hour = payload.hour;
    },
    SET_SUMMARY_MEDIA(state, payload) {
      state.summary_media = payload;
    },
    SET_SUMMARY_PGIE(state, payload) {
      state.summary_pgie = payload;
    },
    SET_SUMMARY_ACTIVITY(state, payload) {
      state.summary_activity = payload;
    },
  },

  actions: {
    DDB_GET_SUMMARY_MEDIA({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_media_${params.timeframe}_user`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit * 2 }
              : {
                  limit: params.limit * 2,
                  where: { customer_id: { _eq: params.customer } },
                };

          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_MEDIA", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_PGIE({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_pgie_${params.timeframe}_user`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_PGIE", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_ACTIVITY({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_activity_${params.timeframe}_user`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_ACTIVITY", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_SUMMARY_MEDIA_MOST_RECENT({ commit }) {
      return new Promise((resolve) => {
        async function get_data() {
          let day_data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.summary_media_1day_user,
              variables: {
                where: {
                  customer_id: { _eq: store.state.user.username },
                },
                limit: 24,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let hour_data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.summary_media_1hour_user,
              variables: {
                where: {
                  customer_id: { _eq: store.state.user.username },
                },
                limit: 24,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_MEDIA_MOST_RECENT", {
            day: day_data.data.data.summary_media_1day_user,
            hour: hour_data.data.data.summary_media_1hour_user,
          });
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_CUSTOMER({ commit, dispatch }, all_data) {
      return new Promise((resolve) => {
        async function get_customer() {
          let customer = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.customer_by_pk,
                variables: {
                  id: store.state.user.username,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let physical_address = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.address_by_pk,
                variables: {
                  customer_id: store.state.user.username,
                  type: "PHYSICAL",
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;

          let mailing_address = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.address_by_pk,
                variables: {
                  customer_id: store.state.user.username,
                  type: "MAILING",
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.address_by_pk;

          let notification_preference = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.notification_preference_by_pk,
                variables: {
                  customer_id: store.state.user.username,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          commit("SET_USER_DATA", {
            customer: customer.data.customer_by_pk,
            physical_address: physical_address.data.address_by_pk,
            mailing_address: mailing_address,
            notification_preference:
              notification_preference.data.notification_preference_by_pk,
          });
          resolve();
        }
        if (all_data) {
          dispatch("DDB_GET_COLLABORATORS");
        }
        get_customer();
      });
    },

    DDB_GET_COLLABORATIONS({ commit }) {
      return new Promise((resolve) => {
        async function get_collaborations() {
          let collaborations = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.share,
                variables: {
                  where: {
                    customer_id_owner: { _eq: store.state.user.username },
                    deleted_at: { _is_null: true },
                  },
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          commit("SET_COLLABORATIONS", collaborations.data.share);
          resolve();
        }
        get_collaborations();
      });
    },

    DDB_GET_COLLABORATORS({ commit }) {
      return new Promise((resolve) => {
        async function get_collaborators() {
          let collaborators = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.share,
                variables: {
                  where: {
                    customer_id_collaborator: {
                      _eq: store.state.user.username,
                    },
                    deleted_at: { _is_null: true },
                  },
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          commit("SET_COLLABORATORS", collaborators.data.share);
          resolve();
        }
        get_collaborators();
      });
    },

    DDB_GET_SITES({ commit, dispatch }) {
      return new Promise((resolve) => {
        async function get_sites() {
          let sites =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.site,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          deleted_at: { _is_null: true },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.site
              : [];
          if (sites.length) {
            dispatch("GET_WEATHER_DATA", [
              sites[0].coordinates_long,
              sites[0].coordinates_lat,
            ]);
            dispatch("DDB_GET_ZONES");
          }
          commit("SET_SITES", sites);
          resolve();
        }
        get_sites();
      });
    },

    DDB_GET_CAMERAS({ commit }) {
      return new Promise((resolve) => {
        async function get_cameras() {
          let cameras =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.camera,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          deleted_at: { _is_null: true },
                        },
                        order_by: {
                          name: "asc",
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          var camera_names_object = {};

          for (const i in cameras.data.camera) {
            camera_names_object[cameras.data.camera[i].id] =
              cameras.data.camera[i].name;
          }

          commit("SET_CAMERAS_NAME_OBJECT", camera_names_object);
          commit("SET_CAMERAS", cameras.data.camera);
          resolve();
        }
        get_cameras();
      });
    },

    DDB_GET_WIFIS({ commit }) {
      return new Promise((resolve) => {
        async function get_wifis() {
          let wifis =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.wifi,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          deleted_at: { _is_null: true },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.wifi
              : [];
          commit("SET_WIFIS", wifis);
          resolve();
        }
        get_wifis();
      });
    },

    DDB_GET_CALENDAR_EVENTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_calendar_events() {
          var calendar_ready_events = [];
          var query_params = { customer_id: { _eq: store.state.customer_id } };

          query_params.datetime_start = {
            _gte: moment(params.date, "YYYY-MM").format("YYYY-MM-DD"),
            _lt: moment(params.date, "YYYY-MM")
              .add(1, "M")
              .format("YYYY-MM-DD"),
          };
          query_params.datetime_end = {
            _gte: moment(params.date, "YYYY-MM").format("YYYY-MM-DD"),
            _lt: moment(params.date, "YYYY-MM")
              .add(1, "M")
              .format("YYYY-MM-DD"),
          };

          if (params.event_type != "ALL") {
            query_params.type = { _eq: params.event_type };
          }

          if (params.association_filter != "ALL") {
            if (params.association == "Herd") {
              query_params.herd_id = { _eq: params.association_filter };
            } else {
              query_params.zone_id = { _eq: params.association_filter };
            }
          }

          if (params.association_filter && params.association_filter.length) {
            query_params.title = { _iregex: params.event_search };
          }
          let events =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.event,
                      variables: {
                        where: {
                          _and: query_params,
                          deleted_at: { _is_null: true },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.event
              : [];

          let notification_counts =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.user_notification_count_by_day,
                      variables: {
                        where: {
                          customer_id: query_params.customer_id,
                          notification_date: query_params.datetime_start,
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.user_notification_count_by_day
              : [];
          for (const i in notification_counts) {
            calendar_ready_events.push({
              id: notification_counts[i].notification_date + "_notification",
              name:
                notification_counts[i].notification_count + " Notifications",
              start: notification_counts[i].notification_date,

              // end: events[i].datetime_end
              //   ? moment(
              //       events[i].datetime_end,
              //       "YYYY-MM-DDTHH:mm:ss.sssZ"
              //     ).format("YYYY-MM-DD HH:mm")
              //   : "",
              color: "BLUE",
              timed: true,
            });
          }

          for (const i in events) {
            calendar_ready_events.push({
              id: events[i].id,
              name: events[i].title,
              start: moment(
                events[i].datetime_start,
                "YYYY-MM-DDTHH:mm:ss.sssZ"
              ).format("YYYY-MM-DD HH:mm"),
              end: events[i].datetime_end
                ? moment(
                    events[i].datetime_end,
                    "YYYY-MM-DDTHH:mm:ss.sssZ"
                  ).format("YYYY-MM-DD HH:mm")
                : "",
              color: events[i].color,
              timed: events[i].datetime_end ? false : true,
            });
          }

          commit("SET_CALENDAR_EVENTS", calendar_ready_events);

          resolve();
        }
        get_calendar_events();
      });
    },

    DDB_GET_HERDS({ commit }) {
      return new Promise((resolve) => {
        async function get_herds() {
          let herds =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.herd,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          deleted_at: { _is_null: true },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.herd
              : [];
          commit("SET_HERDS", herds);

          resolve();
        }
        get_herds();
      });
    },

    DDB_GET_ZONES({ commit }) {
      return new Promise((resolve) => {
        async function get_zones() {
          let zones =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? await axios.post(
                  store.state.hasura_params.url,
                  {
                    query: hasura_queries.zone,
                    variables: {
                      where: {
                        customer_id: { _eq: store.state.customer_id },
                        deleted_at: { _is_null: true },
                      },
                    },
                  },
                  {
                    headers: store.state.hasura_params.headers,
                  }
                )
              : [];
          commit("SET_ZONES", zones.data.data.zone);

          resolve();
        }
        get_zones();
      });
    },

    DDB_GET_LAST_FOOTAGE({ commit }, params) {
      return new Promise((resolve) => {
        async function get_camera(params) {
          let order_by = {};
          order_by[params.sort] = params.sort_direction;
          let where = {
            customer_id: { _eq: store.state.customer_id },
            deleted_at: { _is_null: true },
          };
          if (params.resolution != "All") {
            where["latest_upload_resolution"] = { _eq: params.resolution };
          }

          let lf =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.camera,
                      variables: {
                        where: where,
                        order_by: order_by,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.camera
              : [];

          commit("SET_LAST_FOOTAGE", lf);
          resolve();
        }
        get_camera(params);
      });
    },

    // ANIMALS

    DDB_GET_ANIMALS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_animals(params) {
          let query = "list_animals";
          let query_count = "list_animals_aggregate";

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {
            customer_id: { _eq: store.state.customer_id },
          };

          if (params.herd && params.herd.toLowerCase() != "all") {
            where["herd_id"] = { _eq: params.herd };
          }

          for (const field in params.filter) {
            if (
              params.filter[field] &&
              params.filter[field].toLowerCase() != "all"
            ) {
              where[field] = { _eq: params.filter[field] };
            }
          }
          if (params.treatment_id) {
            where.animal_treatments = {
              treatment_id: { _eq: params.treatment_id },
              deleted_at: { _is_null: true },
            };
          }

          if (params.search && params.search.length) {
            where.tag_label = { _iregex: params.search };
          }

          if (params.chart_query) {
            if (params.chart_query[0] == "cow_breeding_type") {
              where["breeding_history_type"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "cow_dam_status") {
              where["dam_status"] = { _eq: params.chart_query[1] };
              where["classification"] = { _eq: "COW" };
            } else if (params.chart_query[0] == "cow_deaths") {
              where["cod"] = { _eq: params.chart_query[1] };
              where["classification"] = { _eq: "COW" };
            } else if (params.chart_query[0] == "calf_birth_ease") {
              where["birth_ease"] = { _eq: params.chart_query[1] };
              where["classification"] = { _eq: "CALF" };
            } else if (params.chart_query[0] == "calf_sex") {
              where["sex"] = { _eq: params.chart_query[1] };
              where["classification"] = { _eq: "CALF" };
            } else if (params.chart_query[0] == "calf_deaths") {
              where["cod"] = { _eq: params.chart_query[1] };
              where["classification"] = { _eq: "CALF" };
            } else if (params.chart_query[0] == "bull_scrotum_circumference") {
              where["classification"] = { _eq: "BULL" };
              where["measurement_scrotum_circumference"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "bull_sperm_morphology") {
              where["classification"] = { _eq: "BULL" };
              where["measurement_sperm_morphology"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "bull_sperm_motility") {
              where["classification"] = { _eq: "BULL" };
              where["measurement_sperm_motility"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "animals_weight_birth") {
              where["measurement_weight_birth"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "animals_weight_weaning") {
              where["measurement_weight_weaning"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "animals_weight_yearling") {
              where["measurement_weight_yearling"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "animals_weight_mature") {
              where["measurement_weight_mature"] = {
                _gte: params.chart_query[1].split("-")[0],
                _lt: params.chart_query[1].split("-")[1],
              };
            } else if (params.chart_query[0] == "animals_phenotype_hoof") {
              where["phenotype_hoof"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "animals_phenotype_claw") {
              where["phenotype_claw"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "animals_phenotype_udder") {
              where["phenotype_udder"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "animals_phenotype_teat") {
              where["phenotype_teat"] = { _eq: params.chart_query[1] };
            }
          }

          let animals =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries[query],
                      variables: {
                        where: where,
                        order_by: order_by,
                        limit: params.limit,
                        offset: params.offset,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          let animal_count =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries[query_count],
                      variables: {
                        where: where,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          commit("SET_ANIMALS", {
            animals: animals.data[query],
            animal_count: animal_count.data[query_count].aggregate.count,
          });
          resolve();
        }
        get_animals(params);
      });
    },
    DDB_GET_ANIMAL_STATS({ commit }) {
      return new Promise((resolve) => {
        async function get_animal_stats() {
          let animals_by_classification =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_classification,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_classification
              : [];
          let cows_by_breeding_type =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.cows_by_breeding_type,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.cows_by_breeding_type
              : [];
          let cows_by_dam_status =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.cows_by_dam_status,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.cows_by_dam_status
              : [];
          let cows_by_cod =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_cod,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          classification: { _eq: "COW" },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_cod
              : [];
          let calves_by_birth_ease =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.calves_by_birth_ease,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.calves_by_birth_ease
              : [];
          let calves_by_sex =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.calves_by_sex,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.calves_by_sex
              : [];
          let calves_by_cod =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_cod,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                          classification: { _eq: "CALF" },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_cod
              : [];
          let bulls_by_scrotum_circumference =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.bulls_by_scrotum_circumference,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.bulls_by_scrotum_circumference
              : [];
          let bulls_by_sperm_morphology =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.bulls_by_sperm_morphology,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.bulls_by_sperm_morphology
              : [];
          let bulls_by_sperm_motility =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.bulls_by_sperm_motility,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.bulls_by_sperm_motility
              : [];

          commit("SET_ANIMAL_STATS", {
            animals_by_classification: animals_by_classification,
            cows_by_breeding_type: cows_by_breeding_type,
            cows_by_dam_status: cows_by_dam_status,
            cows_by_cod: cows_by_cod,
            calves_by_birth_ease: calves_by_birth_ease,
            calves_by_sex: calves_by_sex,
            calves_by_cod: calves_by_cod,
            bulls_by_scrotum_circumference: bulls_by_scrotum_circumference,
            bulls_by_sperm_morphology: bulls_by_sperm_morphology,
            bulls_by_sperm_motility: bulls_by_sperm_motility,
          });
          resolve();
        }
        get_animal_stats();
      });
    },
    DDB_GET_SELECTION_ANIMALS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_animals(params) {
          let where = {
            customer_id: { _eq: store.state.customer_id },
          };

          if (params.classification) {
            where.classification = { _eq: params.classification };
          }
          if (params.dob) {
            where.dob = { _lt: params.dob };
          }
          if (params.search) {
            where.tag_label = { _iregex: params.search };
          }
          if (params.search_dam && params.active == "dam") {
            where.tag_label = { _iregex: params.search_dam };
          }
          if (params.search_sire && params.active == "sire") {
            where.tag_label = { _iregex: params.search_sire };
          }
          if (params.treatment_id) {
            where._not = {
              animal_treatments: { treatment_id: { _eq: params.treatment_id } },
            };
          }
          let animals =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.list_selection_animals,
                      variables: {
                        where: where,
                        order_by: { tag_label: "asc" },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          commit("SET_SELECTION_ANIMALS", animals.data.list_animals);
          resolve();
        }
        get_animals(params);
      });
    },
    // TREATMENTS
    DDB_GET_TREATMENTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_treatments(params) {
          let query = "treatment";
          let query_count = "treatment_aggregate";

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {
            customer_id: { _eq: store.state.customer_id },
            deleted_at: { _is_null: true },
          };
          for (const field in params.filter) {
            if (
              params.filter[field] &&
              params.filter[field].toLowerCase() != "all"
            ) {
              where[field] = { _eq: params.filter[field] };
            }
          }
          if (params.chart_query) {
            if (params.chart_query[0] == "treatments_last_six_months") {
              where["date"] = {
                _gte: moment(params.chart_query[1], "YYYY-MM").format(
                  "YYYY-MM-DD"
                ),
                _lt: moment(params.chart_query[1], "YYYY-MM")
                  .add(1, "M")
                  .format("YYYY-MM-DD"),
              };
            } else if (params.chart_query[0] == "treatment_reason") {
              where["reason"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "treatment_type") {
              where["type"] = { _eq: params.chart_query[1] };
            } else if (params.chart_query[0] == "treatment_drug_name") {
              query = "list_treatments_by_drug_name";
              query_count = "list_treatments_by_drug_name_aggregate";
              where["drug_name"] = { _eq: params.chart_query[1] };
            }
          }
          let treatments =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries[query],
                      variables: {
                        where: where,
                        order_by: order_by,
                        limit: params.limit,
                        offset: params.offset,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          let treatment_count =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries[query_count],
                      variables: {
                        where: where,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          commit("SET_TREATMENTS", {
            treatments: treatments.data[query],
            treatment_count: treatment_count.data[query_count].aggregate.count,
          });
          resolve();
        }
        get_treatments(params);
      });
    },

    DDB_GET_TREATMENT_STATS({ commit }) {
      return new Promise((resolve) => {
        async function get_treatment_stats() {
          let treatments_by_last_six_months =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.treatments_by_last_six_months,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.treatments_by_last_six_months
              : [];
          let treatments_by_reason =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.treatments_by_reason,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.treatments_by_reason
              : [];
          let treatments_by_type =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.treatments_by_type,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.treatments_by_type
              : [];
          let treatments_by_drug_name =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.treatments_by_drug_name,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.treatments_by_drug_name
              : [];
          commit("SET_TREATMENT_STATS", {
            treatments_by_last_six_months: treatments_by_last_six_months,
            treatments_by_reason: treatments_by_reason,
            treatments_by_type: treatments_by_type,
            treatments_by_drug_name: treatments_by_drug_name,
          });
          resolve();
        }
        get_treatment_stats();
      });
    },

    //MEASUREMENTS
    DDB_GET_MEASUREMENT_STATS({ commit }) {
      return new Promise((resolve) => {
        async function get_measurement_stats() {
          let animals_by_weight_birth =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_weight_birth,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_weight_birth
              : [];
          let animals_by_weight_weaning =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_weight_weaning,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_weight_weaning
              : [];
          let animals_by_weight_yearling =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_weight_yearling,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_weight_yearling
              : [];
          let animals_by_weight_mature =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_weight_mature,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_weight_mature
              : [];
          commit("SET_MEASUREMENT_STATS", {
            animals_by_weight_birth: animals_by_weight_birth,
            animals_by_weight_weaning: animals_by_weight_weaning,
            animals_by_weight_yearling: animals_by_weight_yearling,
            animals_by_weight_mature: animals_by_weight_mature,
          });
          resolve();
        }
        get_measurement_stats();
      });
    },

    //PHENOTYPES
    DDB_GET_PHENOTYPE_STATS({ commit }) {
      return new Promise((resolve) => {
        async function get_phenotype_stats() {
          let animals_by_phenotype_claw =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_phenotype_claw,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_phenotype_claw
              : [];
          let animals_by_phenotype_hoof =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_phenotype_hoof,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_phenotype_hoof
              : [];
          let animals_by_phenotype_udder =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_phenotype_udder,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_phenotype_udder
              : [];
          let animals_by_phenotype_teat =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animals_by_phenotype_teat,
                      variables: {
                        where: {
                          customer_id: { _eq: store.state.customer_id },
                        },
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.animals_by_phenotype_teat
              : [];

          commit("SET_PHENOTYPE_STATS", {
            animals_by_phenotype_claw: animals_by_phenotype_claw,
            animals_by_phenotype_hoof: animals_by_phenotype_hoof,
            animals_by_phenotype_udder: animals_by_phenotype_udder,
            animals_by_phenotype_teat: animals_by_phenotype_teat,
          });
          resolve();
        }
        get_phenotype_stats();
      });
    },

    DDB_GET_ANIMAL_IDENTIFICATIONS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_herds() {
          var where = {
            customer_id: { _eq: store.state.customer_id },
            source: { _eq: "AI_GENERATED" },
            review_status: { _eq: params.status },
            deleted_at: { _is_null: true },
          };
          var order_by = { media_timestamp: "desc" };
          if (params.sort) {
            order_by = [
              { detected_tag_label: params.sort.direction ? "desc" : "asc" },
              { detected_tag_color: "desc" },
            ];
          }
          if (params.date) {
            where.media_timestamp = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "days")
                .format("YYYY-MM-DDTHH:mm:ss"),
            };
          }

          if (params.search && params.search.length) {
            where.detected_tag_label = { _iregex: params.search };
          }

          if (params.color) {
            where.detected_tag_color = { _eq: params.color };
          }
          let images =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.animal_image,
                      variables: {
                        where: where,
                        order_by: order_by,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data
              : [];
          commit("SET_ANIMAL_IDENTIFICATIONS", images.data.animal_image);
          resolve();
        }
        get_herds();
      });
    },

    DDB_SELECTED_ANIMAL_PARENTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_zones() {
          var or_query = [];
          if (params.dam_id) {
            or_query.push({ id: { _eq: params.dam_id } });
          }
          if (params.dam_id) {
            or_query.push({ id: { _eq: params.sire_id } });
          }
          if (!or_query.length) {
            commit("SET_SELECTED_ANIMAL_PARENTS", false);

            resolve();
            return false;
          }
          let selected_animal_parents =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? await axios.post(
                  store.state.hasura_params.url,
                  {
                    query: hasura_queries.list_animals,
                    variables: {
                      where: {
                        _or: [
                          { id: { _eq: params.dam_id } },
                          { id: { _eq: params.sire_id } },
                        ],
                      },
                    },
                  },
                  {
                    headers: store.state.hasura_params.headers,
                  }
                )
              : [];
          var parents = {};
          if (selected_animal_parents.data.data.list_animals[0].sex == "MALE") {
            parents.sire = selected_animal_parents.data.data.list_animals[0];
            parents.dam = selected_animal_parents.data.data.list_animals[1];
          } else {
            parents.dam = selected_animal_parents.data.data.list_animals[0];
            parents.sire = selected_animal_parents.data.data.list_animals[1];
          }
          commit("SET_SELECTED_ANIMAL_PARENTS", parents);

          resolve();
        }
        get_zones();
      });
    },

    DDB_SELECTED_ANIMAL_OFFSPRING({ commit }, id) {
      return new Promise((resolve) => {
        async function get_zones() {
          let selected_animal_parents =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? await axios.post(
                  store.state.hasura_params.url,
                  {
                    query: hasura_queries.list_animals,
                    variables: {
                      where: {
                        _or: [
                          { dam_id: { _eq: id } },
                          { sire_id: { _eq: id } },
                        ],
                      },
                    },
                  },
                  {
                    headers: store.state.hasura_params.headers,
                  }
                )
              : [];

          commit(
            "SET_SELECTED_ANIMAL_OFFSPRING",
            selected_animal_parents.data.data.list_animals
          );

          resolve();
        }
        get_zones();
      });
    },

    DDB_GET_NOTIFICATIONS({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        async function get_events(params) {
          _this.state.event_params = params;

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {
            customer_id: { _eq: store.state.customer_id },
            deleted_at: { _is_null: true },
          };

          if (params.search && params.search.length) {
            where.description = { _iregex: params.search };
          }

          if (params.filter.camera && params.filter.camera != "All") {
            where.camera_id = { _eq: params.filter.camera };
          }
          if (params.filter.level) {
            where.level = { _eq: params.filter.level };
          }
          if (params.date && params.date != "Invalid date") {
            where.datetime = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }
          let notifications = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.notification,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;

          let notification_count =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.notification_aggregate,
                      variables: {
                        where: where,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.notification_aggregate.aggregate.count
              : [];
          commit("SET_NOTIFICATIONS", {
            notifications: notifications.data.notification,
            notification_count: notification_count,
          });
          resolve();
        }
        get_events(params);
      });
    },

    DDB_GET_THUMBNAIL_INFO({ commit }, params) {
      return new Promise((resolve) => {
        async function get_videos() {
          store.state.video_loading = true;

          let order_by = {};

          order_by.media_timestamp = params.sort_direction;

          let where = {
            customer_id: { _eq: store.state.customer_id },
          };
          if (params.camera) {
            where.camera_id = { _eq: params.camera };
          }

          if (params.date) {
            where.media_timestamp = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }

          where.media_type = { _eq: "VIDEO" };

          let videos = (
            await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.inference_media_video,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          store.state.video_loading = false;
          commit("SET_THUMBNAILS", videos.data.inference_media);

          resolve();
        }

        get_videos();
      });
    },

    DDB_GET_NOTES({ commit }, params) {
      return new Promise((resolve) => {
        async function get_notes() {
          let order_by = {};

          order_by.datetime = params.sort_direction;

          let where = {
            customer_id: { _eq: store.state.customer_id },
            deleted_at: { _is_null: true },
          };

          if (params.date) {
            where.datetime = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }

          if (params.search && params.search.length) {
            where.comment = { _iregex: params.search };
          }

          let notes = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.user_note,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.user_note;

          let note_count =
            store.state.allowed_operations == "READ" ||
            store.state.allowed_operations == "WRITE"
              ? (
                  await axios.post(
                    store.state.hasura_params.url,
                    {
                      query: hasura_queries.user_note_aggregate,
                      variables: {
                        where: where,
                      },
                    },
                    {
                      headers: store.state.hasura_params.headers,
                    }
                  )
                ).data.data.user_note_aggregate.aggregate.count
              : [];
          commit("SET_NOTES", { notes: notes, note_count: note_count });

          resolve();
        }
        get_notes();
      });
    },

    DDB_GET_PREDATORS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_predator_count_by_camera,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            commit(
              "SET_ANALYTICS_PREDATORS",
              analytics.data.data.media_predator_count_by_camera
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_PREDATORS", []);
            resolve(false);
          }
        }
        get_analytics();
      });
      // return sem_status.images
    },
    DDB_GET_CONTRACTIONS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_contraction_count_by_camera,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            commit(
              "SET_ANALYTICS_CONTRACTIONS",
              analytics.data.data.media_contraction_count_by_camera
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_CONTRACTIONS", []);
            resolve(false);
          }
        }
        get_analytics();
      });
      // return sem_status.images
    },
    DDB_GET_INSIGHTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries[params.query],
                variables: {
                  where: params.where,
                  limit: params.range * store.state.camera_objects.length,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            var background_color_array = [
              "#42A5F5",
              "#388E3C",
              "#F57C00",
              "#FBC02D",
              "#ae4773",
              "#093D72",
              "#607D8B",
              "#F06292",
              "#8D6E63",
              "#EF5350",
              "#BDBDBD",
            ];
            var timestamp_labels = [];
            var time_unit = "days";

            if (params.bin_size.includes("hour")) {
              time_unit = "hours";
            } else if (params.bin_size.includes("min")) {
              time_unit = "minutes";
            }
            var query = params.query;
            if (params.query.includes("predator")) {
              query = query.replace("predator", "species");
            }
            for (let i = 0; i < params.range; i++) {
              timestamp_labels.push(
                moment(
                  analytics.data.data[query][0].media_timestamp,
                  "YYYY-MM-DDTHH:mm:ss"
                )
                  .subtract(i, time_unit)
                  .format("YYYY-MM-DDTHH:mm:ss")
              );
            }

            const camera_ids = [
              ...new Set(
                analytics.data.data[query].map((item) => item.camera_id)
              ),
            ];

            const datasets_by_camera = camera_ids.map((camera_id, index) => {
              // For each camera_id, map the animal counts to the corresponding timestamp
              const data = timestamp_labels.map((timestamp) => {
                const item = analytics.data.data[query].find(
                  (i) =>
                    i.camera_id === camera_id && i.media_timestamp === timestamp
                );
                return item ? sum_counts(item) : 0; // Use animal_count if available, otherwise 0
              });

              return {
                data,
                borderColor: "#ffff",
                backgroundColor:
                  background_color_array[index % background_color_array.length],
                fill: false,
                label: store.state.camera_names_object[camera_id],
              };
            });

            const datasets_by_species = params.group_by_list.map(
              (detection, index) => {
                // For each camera_id, map the animal counts to the corresponding timestamp
                const data = timestamp_labels.map((timestamp) => {
                  const item = analytics.data.data[query]
                    .filter((i) => i.media_timestamp === timestamp)
                    .reduce(
                      (accumulator, item) => accumulator + item[detection],
                      0
                    );
                  return item ? item : 0; // Use animal_count if available, otherwise 0
                });

                return {
                  data,
                  borderColor: "#ffff",
                  backgroundColor:
                    background_color_array[
                      index % background_color_array.length
                    ],
                  fill: false,
                  label: detection
                    .replace("_count", "")
                    .replace("2", "")
                    .replaceAll("_", " "),
                };
              }
            );

            let display_labels = timestamp_labels.map((dt) => {
              return moment.utc(dt).tz(params.tz).format("MM-DD HH:mm");
            });
            console.log({
              by_camera: [datasets_by_camera, display_labels],
              by_species: [datasets_by_species, display_labels],
            });
            commit("SET_INSIGHTS", {
              by_camera: [datasets_by_camera, display_labels],
              by_species: [datasets_by_species, display_labels],
            });
            resolve();
          } catch (err) {
            console.log(err);
            commit("SET_INSIGHTS", { by_camera: [], by_species: [] });
            resolve(false);
          }
        }
        function sum_counts(item) {
          var count = 0;
          for (const i in params.group_by_list) {
            count += item[params.group_by_list[i]];
          }
          return count;
        }
        get_analytics();
      });
    },

    DDB_GET_ANIMAL_BY_PGIE_COUNT({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_unique_tag_count_by_species,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            commit(
              "SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT",
              analytics.data.data.media_unique_tag_count_by_species
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT", []);
            resolve(false);
          }
        }
        get_analytics();
      });
    },
    DDB_GET_CONTRACTIONS_BY_PGIE_COUNT({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_contraction_count_by_species,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            commit(
              "SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT",
              analytics.data.data.media_contraction_count_by_species
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT", []);
            resolve(false);
          }
        }
        get_analytics();
      });
    },
    DDB_GET_PREDATORS_BY_PGIE_COUNT({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_predator_count_by_species,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }
            commit(
              "SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT",
              analytics.data.data.media_predator_count_by_species
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT", []);
            resolve(false);
          }
        }
        get_analytics();
      });
    },
    DDB_GET_ANIMAL_COUNT({ commit }, params) {
      return new Promise((resolve) => {
        async function get_analytics() {
          try {
            let analytics = await axios.post(
              store.state.hasura_params_inference.url,
              {
                query: hasura_queries.media_unique_tag_count_by_camera,
                variables: {
                  args: params,
                },
              },
              {
                headers: store.state.hasura_params_inference.headers,
              }
            );
            if (analytics.errors || analytics.data.errors) {
              throw new Error("GraphQL error: " + analytics.errors[0].message);
            }

            commit(
              "SET_ANALYTICS_ANIMAL_COUNT",
              analytics.data.data.media_unique_tag_count_by_camera
            );
            resolve();
          } catch {
            commit("SET_ANALYTICS_ANIMAL_COUNT", []);
            resolve(false);
          }
        }
        get_analytics();
      });
      // return sem_status.images
    },
    DDB_GET_IMAGES({ commit, state }, params) {
      console.log(params);
      var request_number = 1;

      if (sem_status.image_number) {
        request_number = sem_status.image_number + 1;
      }
      sem_status.image_number = request_number;
      function list_image_filters(image_filter) {
        for (const i in image_filter) {
          if (image_filter[i]) {
            return i;
          }
        }
        return null;
      }
      function list_image_filters_multiple(image_filter, type) {
        var return_arr = [];
        for (const i in image_filter) {
          if (image_filter[i]) {
            let obj = {}
            obj[type] = {_eq: i}
            return_arr.push(obj)
          }
        }
        if (return_arr.length) {
          return return_arr
        } return null
      }
      return new Promise((resolve) => {
        async function get_images() {
          if (params == "empty") {
            commit("SET_IMAGES", []);
            resolve(null);
            return false;
          }
          // process filters
          // date range
          var date_start = null;
          var date_end = null;
          if (params.date_range) {
            if (params.date_range.length == 1) {
              var date = moment(params.date_range[0]);
              date_start = date.format("YYYY-MM-DDTHH:mm:ss");
              date_end = date.endOf("day").format("YYYY-MM-DDTHH:mm:ss");
            } else {
              var date1 = moment(params.date_range[0]);
              var date2 = moment(params.date_range[1]);
              date_start = date1.format("YYYY-MM-DDTHH:mm:ss");
              date_end = date2.format("YYYY-MM-DDTHH:mm:ss");
            }
          }
          if (!params.load_more) {
            commit("SET_IMAGES", []);
          }

          var classification_filter = {};
          if (list_image_filters_multiple(params.filters.PGIE, 'species_label')) {
            classification_filter["_or"] = list_image_filters_multiple(params.filters.PGIE, 'species_label');
          }
          if (list_image_filters(params.filters.Activity)) {
            classification_filter["activity_label"] = {
              _eq: list_image_filters(params.filters.Activity),
            };
          }
          if (list_image_filters(params.filters.Age)) {
            classification_filter["age_label"] = {
              _eq: list_image_filters(params.filters.Age),
            };
          }
          // if (list_image_filters(params.filters.Contraction2)) {
          //   classification_filter["contraction_label"] = {
          //     _eq: list_image_filters(params.filters.Contraction2),
          //   };
          // }
          // let estrus_filter = list_image_filters(params.filters.Estrus);
          // if (estrus_filter) {
          //   if (estrus_filter.includes("mounting")) {
          //     classification_filter["mounting_label"] = {
          //       _eq: estrus_filter,
          //     };
          //   }
          //   if (estrus_filter.includes("heat")) {
          //     classification_filter["heat_detection_label"] = {
          //       _eq: estrus_filter,
          //     };
          //   }
          //   if (estrus_filter.includes("chin")) {
          //     classification_filter["chinrest_label"] = {
          //       _eq: estrus_filter,
          //     };
          //   }
          // }

          var body_parts_filter = {};
          if (list_image_filters(params.filters.classification)) {
            if (
              ["foot", "joint", "tag"].includes(
                list_image_filters(params.filters.classification)
              )
            ) {
              body_parts_filter[
                list_image_filters(params.filters.classification) + "_1_label"
              ] = {
                _is_null: false,
              };
            }
            //  else {
            //   body_parts_filter[
            //     list_image_filters(params.filters.classification) + "_label"
            //   ] = {
            //     _is_null: false,
            //   };
            // }
          }

          if (params.filters.Mounting) {
            classification_filter["mounting_label"] = { _is_null: false };
          }
          if (params.filters.Contraction) {
            classification_filter["contraction_label"] = { _eq: "tail_up" };
          }

          var pgies_filter = {
            classifications: classification_filter,
            body_parts: body_parts_filter,
          };

          if (list_image_filters(params.filters.classification)) {
            pgies_filter["class_label"] = {
              _eq: list_image_filters(params.filters.classification),
            };
          }

          var cameras = [{ camera_id: { _is_null: false } }];

          if (params.camera_id && params.camera_id.length) {
            cameras = [];
            for (const i in params.camera_id) {
              cameras.push({ camera_id: { _eq: params.camera_id[i] } });
            }
          }

          // if (params.filters.classification) {
          //   pgies_filter.class_label = { _eq: params.filters.classification };
          // }

          if (params.filters.tag_exists) {
            pgies_filter.body_parts = { tag_1_label: { _is_null: false } };
          }

          // _or: [
          //   { id: { _eq: params.dam_id } },
          //   { id: { _eq: params.sire_id } },
          // ],
          var pgie_where = { ...pgies_filter };

          pgie_where.media_timestamp = {
            _gte: date_start,
            _lt: date_end,
          };
          pgie_where._or = cameras;
          pgie_where.customer_id = { _eq: params.user_id };

          try {
            var more_images = false;
            var return_images = [];
            if (
              !params.filters.Contraction &&
              !params.filters.Estrus &&
              !params.filters.Contraction2
            ) {
              let images = await axios.post(
                store.state.hasura_params_inference.url,
                {
                  query: hasura_queries.inference_media,
                  variables: {
                    limit: 96,
                    offset: params.load_more ? state.images.length : 0,
                    where: {
                      _or: cameras,
                      customer_id: { _eq: params.user_id },
                      media_type: { _eq: "IMAGE" },
                      media_timestamp: {
                        _gte: date_start,
                        _lt: date_end,
                      },
                      pgies: pgies_filter,
                    },
                    where_pgie: pgies_filter,
                  },
                },
                {
                  headers: store.state.hasura_params_inference.headers,
                }
              );
              console.log(images)
              if (images.errors || images.data.errors) {
                throw new Error("GraphQL error: " + images.errors[0].message);
              }

              // console.log(images.data.data.list_inference_images)

              let image_list = images.data.data.inference_media;
              return_images = image_list.map((image) => {
                var user_id = image.customer_id;
                var camera_id = image.camera_id;
                var image_time = moment(image.media_timestamp);
                var body_parts_list = [];
                var tags = [];

                var year = String(image_time.year()).padStart(4, "0");
                var month = String(image_time.month() + 1).padStart(2, "0");
                var day = String(image_time.date()).padStart(2, "0");
                var full_date = image_time.format("YYYY-MM-DD_HH-mm-ss");
                image.pgies.map((inference) => {
                  if (inference.body_parts) {
                    const partsToCheck = [
                      "head",
                      "tail",
                      "foot_1",
                      "foot_2",
                      "foot_3",
                      "foot_4",
                      "joint_1",
                      "joint_2",
                      "joint_3",
                      "joint_4",
                      "tag_1",
                      "tag_2",
                    ];

                    partsToCheck.forEach((p) => {
                      const bboxKey = `${p}_bbox`;
                      const confKey = `${p}_conf`;
                      const labelKey = `${p}_label`;

                      if (inference.body_parts[bboxKey] !== null) {
                        const conf = inference.body_parts[confKey];
                        const label = inference.body_parts[labelKey];

                        if (label == "tag") {
                          let tag_id = inference.body_parts[`${p}_id_label`];
                          let tag_id_conf =
                            inference.body_parts[`${p}_id_conf`];
                          let tag_color =
                            inference.body_parts[`${p}_color_label`];

                          if (tag_id_conf && tag_id_conf >= 0.7) {
                            body_parts_list.push([
                              {
                                bbox: inference.body_parts[bboxKey],
                                conf,
                                label,
                                tag_id: tag_id,
                                tag_id_conf: tag_id_conf,
                                tag_color,
                              },
                              inference.tracking_id,
                            ]);
                            tags.push({
                              tag_label: tag_id,
                              tracking_id: inference.tracking_id,
                              tag_color: tag_color,
                              tag_id_conf: tag_id_conf,
                            });
                          }
                        } else {
                          body_parts_list.push([
                            {
                              bbox: inference.body_parts[bboxKey],
                              conf,
                              label,
                            },
                            inference.tracking_id,
                          ]);
                        }
                      }
                    });
                  }
                });
                return {
                  ...image,
                  image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
                  image_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
                  body_parts_list: body_parts_list,
                  tags: tags,
                  hasura_header: store.state.hasura_params_inference.headers,
                  hasura_url: store.state.hasura_params_inference.url,
                };
              });
            } else if (
              params.filters.Contraction ||
              params.filters.Contraction2
            ) {
              let where = {
                _or: cameras,
                customer_id: { _eq: params.user_id },
                media_timestamp: {
                  _gte: date_start,
                  _lt: date_end,
                },
              };
              //         if (list_image_filters(params.filters.Contraction2)) {
              //   where["contraction_label"] = {
              //     _eq: list_image_filters(params.filters.Contraction2),
              //   };
              // }
              let images = await axios.post(
                store.state.hasura_params_inference.url,
                {
                  query: hasura_queries.analytics_calving,
                  variables: {
                    limit: 96,
                    offset: params.load_more ? state.images.length : 0,
                    where: where,
                  },
                },
                {
                  headers: store.state.hasura_params_inference.headers,
                }
              );
              console.log(images, where);
              if (images.errors || images.data.errors) {
                throw new Error("GraphQL error: " + images.errors[0].message);
              }

              // console.log(images.data.data.list_inference_images)

              let image_list = images.data.data.analytics_calving;
              return_images = image_list.map((image) => {
                var user_id = image.customer_id;
                var camera_id = image.camera_id;
                var image_time = moment(image.media_timestamp);
                var body_parts_list = [];
                var tags = [];

                var year = String(image_time.year()).padStart(4, "0");
                var month = String(image_time.month() + 1).padStart(2, "0");
                var day = String(image_time.date()).padStart(2, "0");
                var full_date = image_time.format("YYYY-MM-DD_HH-mm-ss");

                const partsToCheck = ["tail", "tag_1", "tag_2"];

                partsToCheck.forEach((p) => {
                  const bboxKey = `${p}_bbox`;
                  const confKey = `${p}_conf`;
                  const labelKey = `${p}_label`;

                  if (image[bboxKey] !== null) {
                    const conf = image[confKey];
                    const label = image[labelKey];

                    if (label == "tag") {
                      let tag_id = image[`${p}_id_label`];
                      let tag_id_conf = image[`${p}_id_conf`];
                      let tag_color = image[`${p}_color_label`];

                      if (tag_id_conf && tag_id_conf >= 0.7) {
                        body_parts_list.push([
                          {
                            bbox: image[bboxKey],
                            conf,
                            label,
                            tag_id: tag_id,
                            tag_id_conf: tag_id_conf,
                            tag_color,
                          },
                          image.tracking_id,
                        ]);
                        tags.push({
                          tag_label: tag_id,
                          tracking_id: image.tracking_id,
                          tag_color: tag_color,
                          tag_id_conf: tag_id_conf,
                        });
                      }
                    } else {
                      body_parts_list.push([
                        {
                          bbox: image[bboxKey],
                          conf,
                          label: p,
                        },
                        image.tracking_id,
                      ]);
                    }
                  }
                });

                return {
                  ...image,
                  image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
                  image_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
                  body_parts_list: body_parts_list,
                  tags: tags,
                  hasura_header: store.state.hasura_params_inference.headers,
                  hasura_url: store.state.hasura_params_inference.url,
                };
              });
            } else if (params.filters.Estrus) {
              let images = await axios.post(
                store.state.hasura_params_inference.url,
                {
                  query: hasura_queries.analytics_estrus,
                  variables: {
                    limit: 96,
                    offset: params.load_more ? state.images.length : 0,
                    where: {
                      media_type: { _eq: "IMAGE" },
                      _or: cameras,
                      customer_id: { _eq: params.user_id },
                      media_timestamp: {
                        _gte: date_start,
                        _lt: date_end,
                      },
                    },
                  },
                },
                {
                  headers: store.state.hasura_params_inference.headers,
                }
              );
              if (images.errors || images.data.errors) {
                throw new Error("GraphQL error: " + images.errors[0].message);
              }

              // console.log(images.data.data.list_inference_images)

              let image_list = images.data.data.analytics_estrus;
              return_images = image_list.map((image) => {
                var user_id = image.customer_id;
                var camera_id = image.camera_id;
                var image_time = moment(image.media_timestamp);
                var body_parts_list = [];
                var tags = [];

                var year = String(image_time.year()).padStart(4, "0");
                var month = String(image_time.month() + 1).padStart(2, "0");
                var day = String(image_time.date()).padStart(2, "0");
                var full_date = image_time.format("YYYY-MM-DD_HH-mm-ss");

                return {
                  ...image,
                  image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
                  image_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
                  body_parts_list: body_parts_list,
                  tags: tags,
                  hasura_header: store.state.hasura_params_inference.headers,
                  hasura_url: store.state.hasura_params_inference.url,
                };
              });
            }

            if (return_images.length == 96) {
              more_images = true;
            }
            commit(
              "SET_IMAGES",
              params.load_more
                ? state.images.concat(return_images)
                : return_images
            );
            resolve(more_images);
          } catch {
            commit("SET_IMAGES", []);
            resolve(false);
          }
        }
        get_images();
      });
    },
    DDB_VIDEOS_AT_DATE({ commit }) {
      return new Promise((resolve) => {
        async function get_videos_at_date() {
          try {
            let videos_at_dates_max = (
              await axios.post(
                store.state.hasura_params_inference.url,
                {
                  query: hasura_queries.inference_media_video,
                  variables: {
                    order_by: { media_timestamp: "desc" },
                    limit: 1,
                  },
                },
                {
                  headers: store.state.hasura_params.headers,
                }
              )
            ).data.data.inference_media;
            commit(
              "SET_VIDEOS_AT_DATES",
              moment(videos_at_dates_max[0].uploaded_at)
            );
            var timestamp = moment(videos_at_dates_max[0].uploaded_at);
            timestamp.utc();
            resolve([
              moment(videos_at_dates_max[0].uploaded_at).format(
                "YYYY-MM-DD"
              ),
              timestamp,
            ]);
          } catch (error) {
            console.log(error)
            resolve([moment().format("YYYY-MM-DD"), false]);
          }
        }
        get_videos_at_date();
      });
    },
    DDB_GET_CUSTOMER_ID({ commit }, collab) {
      return new Promise((resolve) => {
        async function set_customer_id() {
          commit("SET_CUSTOMER_ID", collab.id);
          commit("SET_ALLOWED_OPERATIONS", collab.permission);
          resolve();
        }
        set_customer_id();
      });
    },

    DDB_GET_ANIMAL({ commit }, id) {
      return new Promise((resolve) => {
        async function get_animal(id) {
          try {
            console.log("get animal ", id);
          } catch (err) {
            console.log(err);
          }

          commit("SET_ANIMAL", [{}, {}, {}, {}, {}]);
          resolve();
        }
        get_animal(id);
      });
    },
    DDB_GET_ENUMS({ commit }) {
      return new Promise((resolve) => {
        function to_items(array) {
          var temp_array = [];
          for (const i in array) {
            if (array[i] != "BCS") {
              temp_array.push({
                value: array[i],
                text: (
                  array[i].charAt(0) + array[i].slice(1).toLowerCase()
                ).replaceAll("_", " "),
              });
            } else {
              temp_array.push({ value: array[i], text: array[i] });
            }
          }
          return temp_array;
        }
        async function get_enums() {
          var temp_enums_items = {};
          try {
            // let [
            //   tag_color,
            //   production_type,
            //   sex_classification,
            //   animal_purpose,
            //   ownership_status,
            //   birth_ease,
            //   nurse_info,
            //   dam_status,
            //   treatment_type,
            //   drug_administration_type,
            //   drug_type,
            //   measurement_type,
            //   phenotype_type,
            //   zone_type,
            //   event_type,
            //   animal_status,
            // ] = await Promise.allSettled([
            //   tag_color_p,
            //   production_type_p,
            //   sex_classification_p,
            //   animal_purpose_p,
            //   ownership_status_p,
            //   birth_ease_p,
            //   nurse_info_p,
            //   dam_status_p,
            //   treatment_type_p,
            //   drug_administration_type_p,
            //   drug_type_p,
            //   measurement_type_p,
            //   phenotype_type_p,
            //   zone_type_p,
            //   event_type_p,
            //   animal_status_p,
            // ]);

            temp_enums_items.phenotype_type = to_items([
              "BCS",
              "CLAW",
              "HOOF",
              "LEG",
              "SCROTUM",
              "SEMEN",
              "TEAT",
              "UDDER",
            ]);
            temp_enums_items.measurement_type = to_items([
              "HEIGHT",
              "PELVIC_HEIGHT",
              "PELVIC_WIDTH",
              "SCROTUM_CIRCUMFERENCE",
              "SPERM_MOTILITY",
              "SPERM_MORPHOLOGY",
              "WEIGHT_BIRTH",
              "WEIGHT_MATURE",
              "WEIGHT_WEANING",
              "WEIGHT_YEARLING",
              "OTHER",
            ]);
            temp_enums_items.drug_type = to_items([
              "ANTIBIOTIC",
              "ANTIPARASITIC",
              "FLUID",
              "PAIN",
              "VITAMIN",
              "VACCINE",
              "OTHER",
            ]);
            temp_enums_items.drug_administration_type = to_items([
              "INTRAVENOUS",
              "INTRAMUSCULAR",
              "INTRAMAMMARY",
              "INTRAUTERINE",
              "ORAL",
              "SUBCUTANEOUS",
              "TOPICAL",
              "OTHER",
              "UNDEFINED",
            ]);
            temp_enums_items.treatment_type = to_items([
              "ANTIBIOTIC",
              "ANTIPARASITIC",
              "BRANDING",
              "DEHORNING",
              "EUTHANASIA",
              "HOOF_TRIMMING",
              "MEDICATION",
              "STERILIZATION",
              "SURGICAL",
              "TAGGING",
              "TATTOO",
              "VACCINATION",
              "OTHER",
            ]);
            temp_enums_items.dam_status = to_items([
              "ON_LEASE",
              "OPEN",
              "MOVED_NEXT_SEASON",
              "STILL_TO_CALF_CURRENT_SEASON",
              "ABORTED_CALF",
              "CALF_AT_SIDE",
              "UNDEFINED",
            ]);
            temp_enums_items.nurse_info = to_items([
              "SINGLE_NURSED_OWN",
              "FOSTERED",
              "TWIN_NURSED_OWN_TOGETHER",
              "TWIN_NURSED_OWN_ALONE",
              "UNDEFINED",
            ]);
            temp_enums_items.ownership_status = to_items([
              "LEASED",
              "OWNED",
              "PURCHASED",
              "OTHER",
              "UNDEFINED",
            ]);
            temp_enums_items.animal_purpose = to_items([
              "BEEF",
              "BREEDING",
              "DAIRY",
              "OTHER",
              "UNDEFINED",
            ]);
            temp_enums_items.sex_classification = to_items([
              "BULL",
              "CALF",
              "COW",
              "FREEMARTIN",
              "HEIFER",
              "STEER",
              "OTHER",
              "UNDEFINED",
            ]);
            temp_enums_items.production_type = to_items([
              "COMMERCIAL",
              "PUREBRED",
              "OTHER",
              "UNDEFINED",
            ]);
            temp_enums_items.tag_color = to_items([
              "BLACK",
              "BLUE",
              "BROWN",
              "GREY",
              "GREEN",
              "ORANGE",
              "PINK",
              "PURPLE",
              "RED",
              "WHITE",
              "YELLOW",
              "UNDEFINED",
            ]);
            temp_enums_items.birth_ease = to_items([
              "UNASISSTED",
              "EASY_PULL",
              "HARD_PULL",
              "MALPRESENTATION",
              "SURGICAL",
              "UNDEFINED",
            ]);
            temp_enums_items.zone_type = to_items([
              "FIELD",
              "PASTURE",
              "HOUSE",
              "BARN",
              "WATER",
              "WATER_SOURCE",
              "TROUGH",
              "MINERAL_BLOCK",
              "UNDEFINED",
            ]);
            temp_enums_items.event_type = to_items([
              "ARRIVAL",
              "AUCTION",
              "BREEDING",
              "CALVING",
              "COMMUNITY_SUPPORT",
              "CULLING",
              "FERTILIZATION",
              "HARVEST",
              "HAYING",
              "HOLIDAY",
              "MEETING",
              "PLANTATION",
              "PREGNANCY_CHECK",
              "PROCESSING",
              "REPAIR",
              "SEEDING",
              "SHIPPING",
              "SOCIAL_EVENT",
              "VACATION",
              "VET_APPOINTMENT",
              "OTHER",
            ]);
            temp_enums_items.animal_status = to_items([
              "ACTIVE",
              "DEAD",
              "EXPORTED",
              "OTHER",
              "UNDEFINED",
            ]);

            commit("SET_ENUMS", temp_enums_items);
          } catch {
            return false;
          }

          resolve();
        }
        get_enums();
      });
    },

    //  Authentication

    async login({ dispatch, state }, { email, password, temp_password }) {
      state.loginError = "";

      try {
        await Auth.signIn(email, temp_password ? temp_password : password).then(
          async function (user) {
            if (
              user.challengeName == "NEW_PASSWORD_REQUIRED" &&
              !temp_password
            ) {
              throw new Error("NEW_PASSWORD_REQUIRED");
            } else if (
              user.challengeName == "NEW_PASSWORD_REQUIRED" &&
              temp_password
            ) {
              Auth.completeNewPassword(
                user, // the Cognito User Object
                password // the new password
                // OPTIONAL, the required attributes
              )
                .then(async function () {
                  // at this time the user is logged in if no MFA required

                  await dispatch("fetchUser");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              await dispatch("fetchUser");
            }
          }
        );
      } catch (err) {
        if (err) state.button = true;
        state.loginError = err.message || err;
        return;
      }
    },

    async authState({ commit, dispatch }, state) {
      if (state === "signedIn") {
        await dispatch("fetchUser");
      } else {
        commit("user", null);
      }
    },

    async fetchUser({ commit, dispatch, state }) {
      try {
        const user = await Auth.currentAuthenticatedUser();

        const expires =
          user.getSignInUserSession().getIdToken().payload.exp -
          Math.floor(new Date().getTime() / 1000);
        //const group = user.getSignInUserSession().getIdToken().decodePayload()["cognito:groups"]

        // console.log(`Token expires in ${expires} seconds`)
        // if (group.includes('Admin')) {
        //   throw "User has Administrator access, please contact OneCup suppoort."
        // }
        setTimeout(async () => {
          //console.log('Renewing Token')
          await dispatch("fetchUser");
        }, expires * 1000);

        commit("user", user);
        // set cookies
        const token = user.getSignInUserSession().getIdToken().jwtToken;
        initializeLambdaClient().then((client) => {
          this.state.lambda_client = client;
        });
        store.state.hasura_params.headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        };
        store.state.hasura_params_write.headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        };
        store.state.hasura_params_inference.headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        };
        commit("SET_JWT_TOKEN", token);
        const lambda_auth_token = "Bearer " + btoa(user.username) + "." + token;
        commit("SET_LAMBDA_AUTH_TOKEN", lambda_auth_token);
        commit("SET_LAMBDA_AUTH_TOKEN_HOME", lambda_auth_token);
        var config = {
          method: "GET",
          withCredentials: true,
          url: process.env.VUE_APP_COOKIE_URL,
          headers: {
            Auth: `Bearer ${token}`,
            Owner: user.username,
          },
        };

        axios(config).catch(function (error) {
          console.log(error);
        });

        if (user) {
          eventBus.$emit("fetched_user");
          // commit("SET_CUSTOMER_ID", user.username);
          // commit("SET_ALLOWED_OPERATIONS", "All");
          // await API.graphql(
          //   graphqlOperation(
          //     subscriptions.onCreateNotification,
          //     { owner: user.username },
          //     store.state.lambda_auth_token
          //   )
          // ).subscribe({
          //   next: () =>
          //     dispatch("DDB_GET_NOTIFICATIONS", this.state.event_params),
          //   error: (error) => console.log(error.error.errors[0]),
          // });
        }
        //authToken: store.state.lambda_auth_token
      } catch (err) {
        state.loginError = err;
        commit("user", null);
        return false;
      }
    },

    async logout({ commit }) {
      await Auth.signOut();
      Object.keys(this.state).forEach((key) => {
        this.state[key] = initial_store[key];
      });
      commit("user", null);
    },
  },

  getters: {
    getSummaryMediaMostRecent1Day(state) {
      return state.summary_media_most_recent_1day;
    },
    getSummaryMediaMostRecent1Hour(state) {
      return state.summary_media_most_recent_1hour;
    },
    getAnimalIdendifications(state) {
      return state.animal_identifications;
    },
    getBBOXColorCode(state) {
      return state.bbox_color_code;
    },
    getImages(state) {
      return state.images;
    },
    getEnums(state) {
      return state.enums;
    },
    getVideosAtDates(state) {
      return state.videos_at_dates;
    },
    getAllowedOperations(state) {
      return state.allowed_operations;
    },
    getCustomerID(state) {
      return state.customer_id;
    },
    getJwtToken(state) {
      return state.jwt_token;
    },
    getLambdaAuthGoken(state) {
      return state.lambda_auth_token;
    },
    getLambdaAuthTokenHome(state) {
      return state.lambda_auth_token_home;
    },
    getCardEvents(state) {
      return state.card_events;
    },
    getLoadingModel(state) {
      return state.loading_model;
    },
    getVideoLoading(state) {
      return state.video_loading;
    },

    getPhenotypesCharts(state) {
      return state.phenotype_charts;
    },
    getScrotumCircumference(state) {
      return state.scrotum_circumference;
    },
    getSpermMorphology(state) {
      return state.sperm_morphology;
    },
    getBulls(state) {
      return state.bulls;
    },

    getCalendarEvents(state) {
      return state.calendar_events;
    },
    getMostRecentAnimals(state) {
      return state.most_recent_animals;
    },
    getZones(state) {
      return state.zones;
    },

    getGroups(state) {
      return state.groups;
    },
    getHerds(state) {
      return state.herds;
    },
    getNotes(state) {
      return state.notes;
    },
    getNoteCount(state) {
      return state.note_count;
    },
    getAnimal(state) {
      return state.animal;
    },
    getCarcasses(state) {
      return state.carcasses;
    },
    getWeightRecords(state) {
      return state.measurements;
    },
    getPhenotypes(state) {
      return state.phenotypes;
    },
    getLastFootage(state) {
      return state.last_footage;
    },

    getWifis(state) {
      return state.wifi_object;
    },

    getCoordinates(state) {
      return state.coordinates;
    },
    getJetson(state) {
      return state.jetson;
    },
    getSites(state) {
      return state.sites;
    },
    getCameraObjects(state) {
      return state.camera_objects;
    },
    getSelectedCamera(state) {
      return state.selected_camera;
    },
    getUser(state) {
      return state.user;
    },
    getUserDDB(state) {
      return state.user_ddb;
    },
    getCameras(state) {
      return state.cameras;
    },
    getCameraTypes(state) {
      return state.cam_types;
    },
    getCameraNames(state) {
      return state.cam_names;
    },
    getVideoList(state) {
      return state.video_list;
    },
    getThumbnails(state) {
      return state.thumbnails;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getNotificationCount(state) {
      return state.notification_count;
    },
    getAlerts(state) {
      return state.alerts;
    },
    getNotices(state) {
      return state.notices;
    },
    getHasuraParams(state) {
      return state.hasura_params;
    },
    getHasuraParamsWrite(state) {
      return state.hasura_params_write;
    },
    getPhysicalAddress(state) {
      return state.physical_address;
    },
    getMailingAddress(state) {
      return state.mailing_address;
    },
    getNotificationPreference(state) {
      return state.notification_preference;
    },
    getCollaborations(state) {
      return state.collaborations;
    },
    getCollaborators(state) {
      return state.collaborators;
    },

    // ANIMALS
    getAnimals(state) {
      return state.animals;
    },
    getAnimalCount(state) {
      return state.animal_count;
    },
    getSelectionAnimals(state) {
      return state.selection_animals;
    },
    getAnimalByClassification(state) {
      return state.animals_by_classification;
    },
    getCowsByBreedingType(state) {
      return state.cows_by_breeding_type;
    },
    getCowsByDamStatus(state) {
      return state.cows_by_dam_status;
    },
    getCowsByCod(state) {
      return state.cows_by_cod;
    },
    getCalvesByBirthEase(state) {
      return state.calves_by_birth_ease;
    },
    getCalvesBySex(state) {
      return state.calves_by_sex;
    },
    getCalvesByCod(state) {
      return state.calves_by_cod;
    },
    getBullsByScrotumCircumference(state) {
      return state.bulls_by_scrotum_circumference;
    },
    getBullsBySpermMorphology(state) {
      return state.bulls_by_sperm_morphology;
    },
    getBullsBySpermMotility(state) {
      return state.bulls_by_sperm_motility;
    },

    // TREATMENTS
    getTreatments(state) {
      return state.treatments;
    },
    getTreatmentCount(state) {
      return state.treatment_count;
    },
    getTreatmentsByLastSixMonths(state) {
      return state.treatments_by_last_six_months;
    },
    getTreatmentsByReason(state) {
      return state.treatments_by_reason;
    },
    getTreatmentsByType(state) {
      return state.treatments_by_type;
    },
    getTreatmentsByDrugName(state) {
      return state.treatments_by_drug_name;
    },

    // MEASUREMENTS
    getMeasurements(state) {
      return state.measurements;
    },
    getMeasurementCount(state) {
      return state.measurement_count;
    },
    getAnimalByWeightBirth(state) {
      return state.animals_by_weight_birth;
    },
    getAnimalByWeightWeaning(state) {
      return state.animals_by_weight_weaning;
    },
    getAnimalByWeightYearling(state) {
      return state.animals_by_weight_yearling;
    },
    getAnimalByWeightMature(state) {
      return state.animals_by_weight_mature;
    },

    // PHENOTYPES
    getAnimalByPhenotypeHoof(state) {
      return state.animals_by_phenotype_hoof;
    },
    getAnimalByPhenotypeClaw(state) {
      return state.animals_by_phenotype_claw;
    },
    getAnimalByPhenotypeUdder(state) {
      return state.animals_by_phenotype_udder;
    },
    getAnimalByPhenotypeTeat(state) {
      return state.animals_by_phenotype_teat;
    },

    getSelectedAnimalParents(state) {
      return state.selected_animal_parents;
    },
    getSelectedAnimalOffspring(state) {
      return state.selected_animal_offspring;
    },

    // INSIGHTS
    getInsightsByCameras(state) {
      return state.insights_by_camera;
    },
    getInsightsBySpecies(state) {
      return state.insights_by_species;
    },

    getAnalyticsPredators(state) {
      return state.analytics_predator;
    },
    getAnalyticsContractions(state) {
      return state.analytics_contraction;
    },
    getAnalyticsAnimalImageCount(state) {
      return state.analytics_animal_image_count;
    },
    getAnalyticsContractionsByPGIECount(state) {
      return state.analytics_contractions_by_pgie_count;
    },
    getAnalyticsPredatorsByPGIECount(state) {
      return state.analytics_predators_by_pgie_count;
    },
    getSummaryMedia(state) {
      return state.summary_media;
    },
    getSummaryPGIE(state) {
      return state.summary_pgie;
    },
    getSummaryActivity(state) {
      return state.summary_activity;
    },
    getCameraNameObject(state) {
      return state.camera_names_object
    }
  },
});

//const month = moment().format('YYYY-MM-DD')
// if (store.state.user) {

// }

const initial_store = { ...store.state };

export default store;
